import React, { useState } from "react";
import SideNavBar from "./SideNavBar";
import TopNavBar from "./TopNavBar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#f0f0f0",
      }}
    >
      <TopNavBar open={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <SideNavBar open={isDrawerOpen} toggleDrawer={toggleDrawer} />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginLeft: isDrawerOpen ? 0 : 0, // Adjust margin based on drawer state
            transition: "margin-left 0.3s ease",
            marginRight: "2%", // End at 90% of the page width
          }}
        >
          {/* Reduced padding between Outlet content and SideNavBar */}
          <div
            style={{
              flex: 1,
              padding: "10px",
              marginTop: "64px",
              overflowY: "auto",
            }}
          >
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
