import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

interface Integration {
  name: string;
  status: "connected" | "disconnected";
  lastSynced: Date;
}

const IntegrationsPage: React.FC = () => {
  // Mock data - replace with actual data fetching logic
  const integrations: Integration[] = [
    {
      name: "Rave EDC (Medidata)",
      status: "connected",
      lastSynced: new Date(new Date().setHours(0, 0, 0, 0) - 86400000),
    },
    // Add more integrations as needed
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Integrations
      </Typography>
      <Grid container spacing={3}>
        {integrations.map((integration) => (
          <Grid item xs={12} sm={6} md={4} key={integration.name}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {integration.name}
                </Typography>
                <Box display="flex" alignItems="center" mb={1}>
                  {integration.status === "connected" ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <ErrorIcon color="error" />
                  )}
                  <Typography variant="body1" ml={1}>
                    {integration.status === "connected"
                      ? "Connected"
                      : "Disconnected"}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Last synced: {integration.lastSynced.toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default IntegrationsPage;
