import { useState, useEffect } from "react";
import Plotly from "plotly.js-dist";

const PLOT_TYPES = [
  { id: "adverse_events", title: "Adverse Events" },
  { id: "ancillary_procedures", title: "Ancillary Procedures" },
  { id: "concomitant_medications", title: "Concomitant Medications" },
  { id: "study_drug", title: "Study Drug" },
  { id: "vital_signs", title: "Vital Signs" },
  { id: "labs_chemistry", title: "Labs (Chemistry)" },
  { id: "labs_hematology", title: "Labs (Hematology)" },
];

const PatientProfilePlots = ({ patientId }) => {
  const [availablePlots, setAvailablePlots] = useState({});
  const [expandedPlots, setExpandedPlots] = useState({});

  useEffect(() => {
    // Reset expanded state when patient changes
    setExpandedPlots({});

    const loadPlots = async () => {
      const paddedId = patientId.toString().padStart(7, "0");

      for (const { id: plotType } of PLOT_TYPES) {
        const plotUrl = `https://octozidemodata.s3.amazonaws.com/PatientProfiles_v2/patient_${paddedId}/${plotType}.json`;
        try {
          const response = await fetch(plotUrl);
          if (response.ok) {
            const plotJson = await response.json();
            setAvailablePlots((prev) => ({ ...prev, [plotType]: true }));

            setTimeout(() => {
              const plotDiv = document.getElementById(`plot-${plotType}`);
              if (plotDiv) {
                try {
                  Plotly.newPlot(
                    `plot-${plotType}`,
                    plotJson.data,
                    {
                      ...plotJson.layout,
                      autosize: true,
                      margin: { t: 50, b: 50, l: 50, r: 50 },
                    },
                    {
                      responsive: true,
                      displayModeBar: false,
                    }
                  );
                } catch (error) {
                  console.error(`Error plotting ${plotType}:`, error);
                  setAvailablePlots((prev) => ({
                    ...prev,
                    [plotType]: false,
                  }));
                }
              }
            }, 0);
          } else {
            setAvailablePlots((prev) => ({ ...prev, [plotType]: false }));
          }
        } catch (error) {
          console.error(`Error fetching ${plotType}:`, error);
          setAvailablePlots((prev) => ({ ...prev, [plotType]: false }));
        }
      }
    };

    loadPlots();

    return () => {
      PLOT_TYPES.forEach(({ id: plotType }) => {
        const plotDiv = document.getElementById(`plot-${plotType}`);
        if (plotDiv) {
          Plotly.purge(plotDiv);
        }
      });
    };
  }, [patientId]);

  const togglePlot = (plotType) => {
    setExpandedPlots((prev) => ({
      ...prev,
      [plotType]: !prev[plotType],
    }));
  };

  return (
    <div className="plots-container">
      {PLOT_TYPES.map(({ id: plotType, title }) => (
        <div key={plotType} className="plot-section">
          <div
            className={`plot-header ${
              availablePlots[plotType] ? "clickable" : ""
            }`}
            onClick={() => availablePlots[plotType] && togglePlot(plotType)}
          >
            <h3 className="plot-title">
              {title}
              {availablePlots[plotType] && (
                <span className="expand-icon">
                  {expandedPlots[plotType] ? "▼" : "▶"}
                </span>
              )}
            </h3>
          </div>
          {availablePlots[plotType] ? (
            <div
              className={`plot-wrapper ${
                expandedPlots[plotType] ? "expanded" : ""
              }`}
            >
              <div id={`plot-${plotType}`} className="plot" />
            </div>
          ) : (
            <p className="no-data-message">No data to produce graph</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PatientProfilePlots;
