import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import API_BASE_URL from "../config";

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [studies, setStudies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjectData = useCallback(async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/get_studies/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setStudies(response.data);
        if (response.data.length > 0 && !selectedProject) {
          setSelectedProject(response.data[0].id);
        }
      } catch (error) {
        console.error("There was an error fetching the studies!", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setStudies([]);
      setSelectedProject("");
      setIsLoading(false);
    }
  }, [selectedProject]);

  useEffect(() => {
    fetchProjectData();
  }, [fetchProjectData]);

  // Add this effect to listen for auth token changes
  useEffect(() => {
    const handleStorageChange = () => {
      fetchProjectData();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [fetchProjectData]);

  const contextValue = {
    selectedProject,
    setSelectedProject,
    studies,
    setStudies,
    isLoading,
    refetchProjectData: fetchProjectData,
  };

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};
