import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./TabbedPlotViewer.css";
import Plotly from "plotly.js-dist";

const PLOT_TYPES = [
  {
    id: "Waterfall",
    label: "Waterfall",
    hasSubplots: false,
    filename: "waterfall_plot.json",
  },
  {
    id: "Tornado",
    label: "Tornado",
    hasSubplots: true,
    options: [
      {
        value: "severity",
        label: "Severity",
        filename: "severity_tornado_plot.json",
      },
      {
        value: "treatment",
        label: "Treatment",
        filename: "treatment_tornado_plot.json",
      },
    ],
  },
  {
    id: "Spaghetti",
    label: "Spaghetti",
    hasSubplots: true,
    options: [
      {
        value: "patient",
        label: "Patients",
        getFilename: (id) => `Patients/spaghetti_plot_patient_${id}.json`,
        data: [
          1, 2, 4, 7, 8, 9, 10, 13, 14, 15, 16, 18, 19, 21, 22, 23, 26, 29,
        ].map((id) => ({
          id,
          label: `Patient ${id}`,
        })),
      },
      {
        value: "location",
        label: "Locations",
        getFilename: (location) =>
          `Locations/spaghetti_plot_location_${location}.json`,
        data: [
          { id: "Prostate", label: "Prostate" },
          { id: "Bone", label: "Bone" },
          { id: "Kidney", label: "Kidney" },
          { id: "Colon", label: "Colon" },
          // { id: "Liver", label: "Liver" },
          { id: "Hilar", label: "Hilar" },
          // Add more locations as needed
        ],
      },
    ],
  },
];

const BASE_URL =
  "https://octozidemodata.s3.us-east-1.amazonaws.com/Visualizations";

const TabbedPlotViewer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [plotHtml, setPlotHtml] = useState({});
  const [selectedOption, setSelectedOption] = useState("severity");
  const [optionsPanelOpen, setOptionsPanelOpen] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (PLOT_TYPES[newValue].id === "Spaghetti") {
      setSelectedOption("patient");
      const defaultData = PLOT_TYPES[newValue].options.find(
        (opt) => opt.value === "patient"
      ).data[0];
      setSelectedRow(defaultData);
    } else if (PLOT_TYPES[newValue].id === "Tornado") {
      setSelectedOption("severity");
      setSelectedRow(null);
    }
  };

  useEffect(() => {
    const currentPlotType = PLOT_TYPES[activeTab];
    let filename;

    console.log("useEffect triggered with:", {
      activeTab,
      currentPlotType: currentPlotType?.id,
      selectedOption,
      selectedRow,
    });

    if (!currentPlotType) return;

    if (currentPlotType.id === "Tornado") {
      const selectedOptionData = currentPlotType.options.find(
        (opt) => opt.value === selectedOption
      );
      filename = selectedOptionData?.filename;
    } else if (currentPlotType.id === "Spaghetti" && selectedRow) {
      const selectedOptionData = currentPlotType.options.find(
        (opt) => opt.value === selectedOption
      );
      if (selectedOptionData) {
        filename = selectedOptionData.getFilename(selectedRow.id);
      }
    } else {
      filename = currentPlotType.filename;
    }

    const cacheKey =
      currentPlotType.id === "Spaghetti"
        ? `${currentPlotType.id}_${selectedOption}_${selectedRow?.id}`
        : `${currentPlotType.id}_${selectedOption}`;

    if (!filename) return;

    if (!plotHtml[cacheKey]) {
      const loadPlot = async () => {
        console.log("Starting to load plot:", {
          plotType: currentPlotType.id,
          option: selectedOption,
          filename,
          rowId: selectedRow?.id,
          cacheKey,
        });

        setLoading(true);
        try {
          let url;
          switch (currentPlotType.id) {
            case "Spaghetti":
              url = `${BASE_URL}/Spaghetti/${filename}`;
              break;
            case "Tornado":
              url = `${BASE_URL}/Tornado/${filename}`;
              break;
            case "Waterfall":
              url = `${BASE_URL}/Waterfall/${filename}`;
              break;
            default:
              url = `${BASE_URL}/${currentPlotType.id}/${filename}`;
          }

          console.log("Fetching from URL:", url);

          const response = await axios.get(url);
          console.log("Response received:", {
            status: response.status,
            data: response.data,
          });

          const plotContainer = document.getElementById("plot-container");
          if (plotContainer) {
            plotContainer.innerHTML = "";

            const plotDiv = document.createElement("div");
            plotDiv.style.width = "100%";
            plotDiv.style.height =
              currentPlotType.id === "Tornado" ? "1200px" : "100%";
            plotContainer.appendChild(plotDiv);

            const plotData = response.data;

            if (Array.isArray(plotData.data)) {
              let plotHeight;
              if (currentPlotType.id === "Tornado" && plotData.data[0]?.y) {
                const totalDataPoints = plotData.data[0].y.length;
                const heightPerPoint = 50;
                plotHeight = totalDataPoints * heightPerPoint;

                // Modify the data to ensure proper spacing
                plotData.data = plotData.data.map((trace) => ({
                  ...trace,
                  orientation: "h",
                  type: "bar",
                }));
              }

              await Plotly.newPlot(
                plotDiv,
                plotData.data,
                {
                  ...plotData.layout,
                  autosize: true,
                  ...(currentPlotType.id === "Tornado" && {
                    height: plotHeight,
                    margin: {
                      l: 400,
                      r: 50,
                      t: 50,
                      b: 100,
                      pad: 4,
                    },
                    yaxis: {
                      ...plotData.layout?.yaxis,
                      automargin: true,
                      autorange: true, // Enable autorange for initial scaling
                      side: "left",
                      fixedrange: false,
                      tickfont: {
                        size: 14,
                      },
                      dtick: 1,
                      tickmode: "linear",
                    },
                    xaxis: {
                      ...plotData.layout?.xaxis,
                      autorange: true,
                      tickfont: {
                        size: 14,
                      },
                    },
                    bargap: 0.4,
                    bargroupgap: 0.2,
                    showlegend: true,
                    legend: {
                      x: 1.1,
                      y: 1,
                    },
                  }),
                },
                {
                  ...plotData.config,
                  responsive: true,
                  scrollZoom: false,
                  displayModeBar: true,
                  modeBarButtonsToRemove: [
                    "zoom2d",
                    "select2d",
                    "lasso2d",
                    "resetScale2d",
                  ],
                }
              );
              console.log("Plot rendered successfully");
            } else {
              console.error("Invalid plot data format:", plotData);
              setError("Invalid plot data format");
            }
          } else {
            console.warn("Plot container not found");
          }

          setPlotHtml((prev) => ({
            ...prev,
            [cacheKey]: response.data,
          }));
        } catch (error) {
          console.error("Error loading plot:", {
            error,
            message: error.message,
            plotType: currentPlotType.id,
            filename,
          });
          setError(`Failed to load ${currentPlotType.label}`);
        } finally {
          setLoading(false);
        }
      };

      loadPlot();
    } else {
      const plotContainer = document.getElementById("plot-container");
      if (plotContainer) {
        plotContainer.innerHTML = "";
        const plotDiv = document.createElement("div");
        plotDiv.style.width = "100%";

        const plotData = plotHtml[cacheKey];

        let plotHeight;
        if (currentPlotType.id === "Tornado" && plotData.data[0]?.y) {
          const totalDataPoints = plotData.data[0].y.length;
          const heightPerPoint = 50;
          plotHeight = totalDataPoints * heightPerPoint;

          // Modify the data to ensure proper spacing
          plotData.data = plotData.data.map((trace) => ({
            ...trace,
            orientation: "h",
            type: "bar",
          }));
        }

        plotDiv.style.height =
          currentPlotType.id === "Tornado" ? `${plotHeight}px` : "100%";
        plotContainer.appendChild(plotDiv);

        // Use the same plot configuration as above
        Plotly.newPlot(
          plotDiv,
          plotData.data,
          {
            ...plotData.layout,
            autosize: true,
            ...(currentPlotType.id === "Tornado" && {
              height: plotHeight,
              margin: {
                l: 400,
                r: 50,
                t: 50,
                b: 100,
                pad: 4,
              },
              yaxis: {
                ...plotData.layout?.yaxis,
                automargin: true,
                autorange: true, // Enable autorange for initial scaling
                side: "left",
                fixedrange: false,
                tickfont: {
                  size: 14,
                },
                dtick: 1,
                tickmode: "linear",
              },
              xaxis: {
                ...plotData.layout?.xaxis,
                autorange: true,
                tickfont: {
                  size: 14,
                },
              },
              bargap: 0.4,
              bargroupgap: 0.2,
              showlegend: true,
              legend: {
                x: 1.1,
                y: 1,
              },
            }),
          },
          {
            ...plotData.config,
            responsive: true,
            scrollZoom: false,
            displayModeBar: true,
            modeBarButtonsToRemove: [
              "zoom2d",
              "select2d",
              "lasso2d",
              "resetScale2d",
            ],
          }
        );
      }
    }

    return () => {
      const plotContainer = document.getElementById("plot-container");
      if (plotContainer) {
        plotContainer.innerHTML = "";
      }
    };
  }, [activeTab, selectedOption, selectedRow, plotHtml]);

  const handleOptionChange = (event) => {
    const newOption = event.target.value;
    console.log("Option changed:", {
      from: selectedOption,
      to: newOption,
    });
    setSelectedOption(newOption);

    const currentPlot = PLOT_TYPES[activeTab];
    const newOptionData = currentPlot.options.find(
      (opt) => opt.value === newOption
    );
    if (newOptionData && newOptionData.data && newOptionData.data.length > 0) {
      setSelectedRow(newOptionData.data[0]);
    }
  };

  const renderOptionsPanel = () => {
    const currentPlot = PLOT_TYPES[activeTab];
    if (!currentPlot?.hasSubplots) return null;

    const currentOptionData = currentPlot.options.find(
      (opt) => opt.value === selectedOption
    );

    return (
      <Box
        sx={{
          position: "relative",
          width: optionsPanelOpen ? "200px" : "30px",
          transition: "width 0.3s ease",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <IconButton
          onClick={() => setOptionsPanelOpen(!optionsPanelOpen)}
          sx={{
            position: "absolute",
            right: "-20px",
            top: "10px",
            zIndex: 1,
            backgroundColor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            "&:hover": {
              backgroundColor: "background.paper",
            },
          }}
          size="small"
        >
          {optionsPanelOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>

        <Box
          sx={{
            overflow: "hidden",
            opacity: optionsPanelOpen ? 1 : 0,
            transition: "opacity 0.3s ease",
            visibility: optionsPanelOpen ? "visible" : "hidden",
            p: 2,
          }}
        >
          {currentPlot.id === "Tornado" && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Plot Type</FormLabel>
              <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                {currentPlot.options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          {currentPlot.id === "Spaghetti" && currentOptionData && (
            <Box>
              <FormControl component="fieldset">
                <FormLabel component="legend">Plot Type</FormLabel>
                <RadioGroup
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  {currentPlot.options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {currentOptionData.data && (
                <TableContainer sx={{ maxHeight: 400, mt: 2 }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {selectedOption === "patient"
                            ? "Patient ID"
                            : "Location"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentOptionData.data.map((item) => (
                        <TableRow
                          key={item.id}
                          hover
                          selected={selectedRow?.id === item.id}
                          onClick={() => {
                            console.log("Row selected:", {
                              previousRow: selectedRow,
                              newRow: item,
                            });
                            setSelectedRow(item);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell>{item.label}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const currentPlotType = PLOT_TYPES[activeTab];

  return (
    <Box
      className="plot-viewer-container"
      sx={{ maxWidth: 1200, margin: "0 auto", p: 3 }}
    >
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Data Review Plots
        </Typography>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          {PLOT_TYPES.map((plot) => (
            <Tab key={plot.id} label={plot.label} />
          ))}
        </Tabs>
      </Paper>
      <Paper
        elevation={3}
        className="plot-paper"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: currentPlotType?.id === "Tornado" ? "1200px" : "600px",
          height: "auto", // Allow height to grow with content
          overflow: "auto", // Enable scrolling if needed
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            height: "auto", // Changed from 100% to auto
            flexGrow: 1,
          }}
        >
          {renderOptionsPanel()}
          <Box
            sx={{
              flexGrow: 1,
              transition: "margin-left 0.3s ease",
              ml: 2,
              height: "auto", // Changed from 100% to auto
              display: "flex",
              overflow: "auto", // Changed from hidden to auto
              position: "relative",
            }}
          >
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                minHeight="600px"
              >
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                minHeight="600px"
              >
                <Typography color="error">{error}</Typography>
              </Box>
            ) : (
              <div
                id="plot-container"
                className={`plot-container ${
                  currentPlotType?.id === "Tornado" ? "tornado" : ""
                }`}
              />
            )}
          </Box>
        </Box>
      </Paper>{" "}
    </Box>
  );
};

export default TabbedPlotViewer;
