import axios from "axios";
import API_BASE_URL from "../config";

const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/login/`, {
      username: username,
      password: password,
    });

    const token = response.data.token;

    // Store the token in localStorage
    localStorage.setItem("authToken", token);

    return token;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export { login };
