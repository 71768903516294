import React from "react";
import { Link, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer
      style={{
        textAlign: "center",
        padding: "2px",
        backgroundColor: "secondary",
      }}
    >
      {/* <p>© 2024 octozi. All rights reserved.</p> */}

      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright \u00A9 "}
        <Link color="inherit" href="https://octozi.com/">
          Octozi
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </footer>
  );
};

export default Footer;
