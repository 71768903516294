import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#3d5a80", // "3d5a80", "1976d2"
    },
    secondary: {
      main: "#dc004e",
    },
    gray: {
      main: "#e6e6e6",
    },
    orange: {
      main: "#ee6c4d",
    },
    darkblue: {
      main: "#293241",
    },
  },
});

export { defaultTheme };
