import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function InfoTooltip({ tooltipText }) {
  return (
    <Tooltip title={tooltipText} arrow>
      <IconButton size="small" style={{ padding: 0, marginLeft: 4 }}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
