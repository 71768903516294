import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const DocumentViewer = () => {
  const documents = [
    {
      id: 1,
      title: "Protocol",
      fileUrl:
        "https://octozidemodata.s3.amazonaws.com/CC-5013-PC-002+Final+Protocol_Redacted.pdf",
    },
    {
      id: 2,
      title: "Case Report Forms",
      fileUrl:
        "https://octozidemodata.s3.amazonaws.com/blankCRF_RedactedMAINSAIL.pdf",
    },
    {
      id: 3,
      title: "Lenalidomide Drug Label",
      fileUrl:
        "https://octozidemodata.s3.amazonaws.com/Lenalidomide+Drug+Label.pdf",
    },
  ];

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true); // Ensure the browser prompts the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={4}
    >
      {documents.map((doc) => (
        <Card
          key={doc.id}
          sx={{
            mb: 3,
            width: "100%",
            maxWidth: 500,
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div">
              {doc.title}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={() => handleDownload(doc.fileUrl)}
              fullWidth
            >
              Download
            </Button>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default DocumentViewer;
