import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import API_BASE_URL from "../../../config";

const PatientProfilesListingsPage = () => {
  const navigate = useNavigate();
  const [patientProfiles, setPatientProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchPatientProfiles = async () => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/patient-profiles/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setPatientProfiles(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching patient profiles:", error);
        setError("Failed to load patient profiles");
        setLoading(false);
      }
    };

    fetchPatientProfiles();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (patientId) => {
    navigate(`/visualizations/patient-profiles/${patientId}`);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Patient Profiles
      </Typography>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="patient profiles table">
            <TableHead>
              <TableRow>
                <TableCell>Patient ID</TableCell>
                <TableCell>Site ID</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Sex</TableCell>
                <TableCell>Race</TableCell>
                {/* <TableCell>Folder Name</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {patientProfiles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((profile) => (
                  <TableRow
                    hover
                    key={profile.patient_id}
                    onClick={() => handleRowClick(profile.patient_id)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    <TableCell>{profile.patient_id}</TableCell>
                    <TableCell>{profile.site_id}</TableCell>
                    <TableCell>{profile.age}</TableCell>
                    <TableCell>{profile.sex || "N/A"}</TableCell>
                    <TableCell>{profile.race || "N/A"}</TableCell>
                    {/* <TableCell>{profile.folder_name}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={patientProfiles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PatientProfilesListingsPage;
