import React, { useState, useEffect } from "react";
import axios from "axios";
import API_BASE_URL from "../../../config";
import BaseReconciliationPage from "./BaseReconciliationPage";
import { useProject } from "../../../contexts/ProjectContext";

const AEReconciliationPage = () => {
  const { selectedProject } = useProject();
  const [aeEntries, setAEEntries] = useState([]);
  const [aeEntriesWithErrors, setAEEntriesWithErrors] = useState([]);
  const [aeEntriesWithDiscrepancies, setAEEntriesWithDiscrepancies] = useState(
    []
  );

  // Get the number of AE entries
  useEffect(() => {
    const fetchAEEntries = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/get_ae_entries/${selectedProject}/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntries(response.data);
        } catch (error) {
          console.error("Error fetching AE entries:", error);
        }
      }
    };

    fetchAEEntries();
  }, [selectedProject]);

  //// Get the number of AE Entries with errors
  useEffect(() => {
    const fetchAEEntriesWithErrors = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/ae-entries-with-errors/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntriesWithErrors(response.data);
        } catch (error) {
          console.error("Error fetching AE entries with errors:", error);
        }
      }
    };

    fetchAEEntriesWithErrors();
  }, [selectedProject]);

  //// Get the number of AE Entries with discrepancies
  useEffect(() => {
    const fetchAEEntriesWithDiscrepancies = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/ae-entries-with-discrepancies/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntriesWithDiscrepancies(response.data);
        } catch (error) {
          console.error("Error fetching AE entries with discrepancies:", error);
        }
      }
    };

    fetchAEEntriesWithDiscrepancies();
  }, [selectedProject]);

  const stats = {
    discrepancies: {
      all: aeEntriesWithDiscrepancies.length,
      major: aeEntriesWithErrors.length,
    },
    unreviewed: { all: aeEntries.length },
    openQueries: 0,
  };
  const api_url = `${API_BASE_URL}/api/get_ae_entries/${selectedProject}/`;
  const discrepancy_status_url = `${API_BASE_URL}/api/ae-entries-discrepancy-status/`;
  const get_entry_details_url = `${API_BASE_URL}/api/ae-entry/<int:ae_entry_id>/`;
  return (
    <BaseReconciliationPage
      title="Adverse Event Entries"
      stats={stats}
      api_url={api_url}
      discrepancy_status_url={discrepancy_status_url}
      route="/ae-reconciliation"
      get_entry_details_url={get_entry_details_url}
    />
  );
};

export default AEReconciliationPage;
