import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Paper,
  Table,
  Tooltip,
  TableHead,
} from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../misc/TooltipIcon";
import { useProject } from "../../../contexts/ProjectContext";

const categoryColors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#FF9F40",
  "#FF6384",
  "#C9CBCF",
  "#4BC0C0",
  "#FF6384",
];

const BaseReconciliationPage = ({
  title,
  stats,
  api_url,
  route,
  discrepancy_status_url,
  get_entry_details_url,
}) => {
  // Get global project state
  const { selectedProject } = useProject();

  // State for entry reports
  const [entries, setEntries] = useState([]);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("Unreviewed");
  const [discrepancyStatus, setDiscrepancyStatus] = useState([]);
  const navigate = useNavigate();

  const [discrepancyCategories, setDiscrepancyCategories] = useState([]);

  const [entrySummaries, setEntrySummaries] = useState({});

  const fetchEntrySummary = async (index) => {
    try {
      const response = await fetch(
        `/ae_entry_summaries/entry_summary_${index}.txt`
      );
      if (response.ok) {
        const text = await response.text();
        return text.trim() || "No generated summary created for this entry";
      } else {
        throw new Error("Failed to fetch summary");
      }
    } catch (error) {
      console.error("Error fetching entry summary:", error);
      return "No generated summary created for this entry";
    }
  };

  const getAeEntryDetails = useCallback(async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("No authentication token found");
      return;
    }
  }, [api_url]);

  useEffect(() => {
    if (entries.length > 0) {
      getAeEntryDetails();
    }
  }, [entries, getAeEntryDetails]);

  // Table of Reconciliation entries
  useEffect(() => {
    if (selectedProject) {
      const token = localStorage.getItem("authToken");
      if (token) {
        axios
          .get(`${api_url}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            const scoredEntries = response.data.map((entry) => ({
              ...entry,
              score: calculateEntryScore(entry),
            }));
            setEntries(scoredEntries);
            // Extract unique discrepancy categories from entries
            const categories = [
              ...new Set(
                response.data.map((entry) => entry.discrepancy_category)
              ),
            ];
            setDiscrepancyCategories(
              categories.filter(
                (category) => category !== "None" && category !== null
              )
            );
          })
          .catch((error) => {
            console.error(
              "There was an error fetching the entry reports!",
              error
            );
          });
      } else {
        console.error("No authentication token found");
      }
    }
  }, [selectedProject, api_url]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      axios
        .get(discrepancy_status_url, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          setDiscrepancyStatus(response.data);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the discrepancy status!",
            error
          );
        });
    }
  }, [discrepancy_status_url]);

  //
  const handleStatusChange = (status) => {
    setStatusFilter(status);
  };

  const handleRowClick = (id) => {
    navigate(`${route}/entry-report/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log("entries", entries);

  const filteredReports = entries.filter(
    (entry) => entry.review_status === statusFilter
  );

  const sortedReports = filteredReports.sort((a, b) => b.score - a.score);

  const paginatedReports = sortedReports.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const calculateEntryScore = (entry) => {
    let score = 0;

    // Discrepancy status
    if (entry.discrepancy_status === "Error") {
      score += 100;
    } else if (entry.discrepancy_status === "Warning") {
      score += 50;
    } else if (entry.discrepancy_status === "Success") {
      score += 0;
    }

    // Severity grade
    switch (entry.severity_grade) {
      case "Death/Grade 5":
        score += 50;
        break;
      case "Life-threatening/Grade 4":
        score += 40;
        break;
      case "Severe/Grade 3":
        score += 30;
        break;
      case "Moderate/Grade 2":
        score += 20;
        break;
      case "Mild/Grade 1":
        score += 10;
        break;
      default:
        break;
    }

    // Related to treatment
    if (entry.related_to_treatment === "Suspected") {
      score += 75;
    }

    return score;
  };

  return (
    <>
      <Card
        sx={{
          height: "10%",
          flexGrow: 1,
          mb: 4,
          mt: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="title"
                gutterBottom
                sx={{ textAlign: "left" }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={2} columns={12} sx={{ mb: 4 }}>
        {/* Entries */}
        <Grid item xs={6}>
          <Card sx={{ height: "100%", flexGrow: 1 }}>
            <CardContent>
              <Typography component="h2" variant="h6" gutterBottom>
                Entries
                <InfoTooltip tooltipText="Breakdown of all entries: no, major, and non-major discrepancies." />
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={7}>
                  <PieChart
                    series={[
                      {
                        data: [
                          {
                            id: 0,
                            value: entries.length - stats.discrepancies.all,
                            label: "No Discrepancies",
                            color: "#4caf50",
                          },
                          {
                            id: 1,
                            value:
                              stats.discrepancies.all -
                              stats.discrepancies.major,
                            label: "Non-Major",
                            color: "#ff9800",
                          },
                          {
                            id: 2,
                            value: stats.discrepancies.major,
                            label: "Major",
                            color: "#f44336",
                          },
                        ],
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                        arcLabel: (item) => `${item.value}`,
                        arcLabelMinAngle: 45,
                      },
                    ]}
                    width={400}
                    height={250}
                    margin={{ top: 0, right: 10, bottom: 40, left: 10 }}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "bottom", horizontal: "middle" },
                        padding: 0,
                        labelStyle: {
                          fontSize: 12,
                        },
                      },
                    }}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontWeight: "bold",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" gutterBottom>
                    <strong>No Discrepancies Entries:</strong>{" "}
                    {entries.length - stats.discrepancies.all}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Major Discrepancies:</strong>{" "}
                    {stats.discrepancies.major}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Non-Major Discrepancies:</strong>{" "}
                    {stats.discrepancies.all - stats.discrepancies.major}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body1" gutterBottom>
                    <strong>Total Entries:</strong> {entries.length}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Discrepancy Categories */}
        <Grid item xs={6}>
          <Card sx={{ height: "100%", flexGrow: 1 }}>
            <CardContent>
              <Typography component="h2" variant="h6" gutterBottom>
                Discrepancy Categories
                <InfoTooltip tooltipText="Breakdown of discrepancies by category." />
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PieChart
                      series={[
                        {
                          data:
                            discrepancyCategories.length > 0
                              ? discrepancyCategories.map(
                                  (category, index) => ({
                                    id: index,
                                    value: entries.filter(
                                      (entry) =>
                                        entry.discrepancy_category === category
                                    ).length,
                                    label: category,
                                    color:
                                      categoryColors[
                                        index % categoryColors.length
                                      ],
                                  })
                                )
                              : [
                                  {
                                    id: 0,
                                    value: 1,
                                    label: "No Data",
                                    color: "#C9CBCF",
                                  },
                                ],
                          highlightScope: {
                            faded: "global",
                            highlighted: "item",
                          },
                          faded: {
                            innerRadius: 30,
                            additionalRadius: -30,
                            color: "gray",
                          },
                          arcLabel: (item) => `${item.value}`,
                          arcLabelMinAngle: 45,
                        },
                      ]}
                      width={500}
                      height={250}
                      margin={{ top: 0, right: 350, bottom: 0, left: 10 }}
                      slotProps={{
                        legend: {
                          direction: "column",
                          position: { vertical: "middle", horizontal: "right" },
                          padding: 0,
                          labelStyle: {
                            fontSize: 12,
                          },
                        },
                      }}
                      sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                          fill: "white",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>{" "}
      </Grid>

      <Container maxWidth={false} disableGutters>
        <Box display="flex" justifyContent="flex-end" m={2}>
          <Button
            variant="contained"
            color={statusFilter === "Unreviewed" ? "primary" : "gray"}
            onClick={() => handleStatusChange("Unreviewed")}
          >
            Unreviewed (
            {
              entries.filter((entry) => entry.review_status === "Unreviewed")
                .length
            }
            )
          </Button>
          <Button
            variant="contained"
            color={statusFilter === "Reviewed" ? "primary" : "gray"}
            onClick={() => handleStatusChange("Reviewed")}
            style={{ marginLeft: "10px" }}
          >
            Reviewed (
            {
              entries.filter((entry) => entry.review_status === "Reviewed")
                .length
            }
            )
          </Button>
          <Button
            variant="contained"
            color={statusFilter === "On Hold" ? "primary" : "gray"}
            onClick={() => handleStatusChange("On Hold")}
            style={{ marginLeft: "10px" }}
          >
            On Hold (
            {
              entries.filter((entry) => entry.review_status === "On Hold")
                .length
            }
            )
          </Button>
          <Button
            variant="contained"
            color={statusFilter === "Queried" ? "primary" : "gray"}
            onClick={() => handleStatusChange("Queried")}
            style={{ marginLeft: "10px" }}
          >
            Queried (
            {
              entries.filter((entry) => entry.review_status === "Queried")
                .length
            }
            )
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ width: "100%", overflowX: "auto" }}
        >
          <Table size="small" sx={{ margin: "auto" }}>
            <TableHead>
              <TableRow>
                {/* <TableCell align="center">Entry ID</TableCell> */}
                <TableCell align="left">
                  Entry Date
                  <InfoTooltip tooltipText="Date format: YYYY-MM-DD" />
                </TableCell>
                <TableCell align="center">Patient ID</TableCell>
                <TableCell align="center">Site ID</TableCell>
                <TableCell align="left">AE Term</TableCell>
                <TableCell align="left">Treatment Related</TableCell>
                <TableCell align="left">Severity Grade</TableCell>
                <TableCell align="left">Discrepancy Category</TableCell>
                <TableCell align="center">Discrepancy</TableCell>
                {/* <TableCell align="center">Score</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedReports.map((entry, index) => (
                <Tooltip
                  key={entry.id}
                  title={
                    <div
                      style={{
                        whiteSpace: "pre-wrap",
                        maxWidth: "500px",
                        maxHeight: "300px",
                        overflow: "auto",
                      }}
                    >
                      {entrySummaries[entry.index] || "Loading..."}
                    </div>
                  }
                  arrow
                  placement="top"
                  followCursor
                  onOpen={() => {
                    if (!entrySummaries[entry.index]) {
                      fetchEntrySummary(entry.index).then((summary) => {
                        setEntrySummaries((prev) => ({
                          ...prev,
                          [entry.index]: summary,
                        }));
                      });
                    }
                  }}
                >
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(entry.id)}
                  >
                    {/* <TableCell align="center">{entry.index}</TableCell> */}
                    <TableCell align="left">
                      {entry.date_of_creation
                        ? new Date(entry.date_of_creation)
                            .toISOString()
                            .split("T")[0]
                        : ""}
                    </TableCell>
                    <TableCell align="center">{entry.patient_id}</TableCell>
                    <TableCell align="center">{entry.site_id}</TableCell>
                    <TableCell align="left">{entry.ae_term}</TableCell>
                    <TableCell align="left">
                      {entry.related_to_treatment}
                    </TableCell>
                    <TableCell align="left">{entry.severity_grade}</TableCell>
                    <TableCell align="left">
                      {entry.discrepancy_category}
                    </TableCell>
                    <TableCell align="center">
                      {(() => {
                        switch (entry.discrepancy_status) {
                          case "Success":
                            return (
                              <Tooltip title="No Discrepancies">
                                <CheckCircleIcon color="success" />
                              </Tooltip>
                            );
                          case "Warning":
                            return (
                              <Tooltip title="Non-Major Discrepancy">
                                <WarningIcon color="warning" />
                              </Tooltip>
                            );
                          case "Error":
                            return (
                              <Tooltip title="Major Discrepancy">
                                <ErrorIcon color="error" />
                              </Tooltip>
                            );
                          default:
                            return null;
                        }
                      })()}
                    </TableCell>
                    {/* <TableCell align="center">{entry.score}</TableCell> */}
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={entries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Container>
    </>
  );
};

export default BaseReconciliationPage;
