import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TextField,
  TablePagination,
  Tooltip,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../config";

// Add this mock data at the top of the file, outside the component
const MOCK_NARRATIVES = [
  {
    id: 1,
    usubjid: "PAT-001",
    aestdat: "2024-01-15",
    aeterm: "Severe Headache",
    aeser: "Yes",
  },
  {
    id: 2,
    usubjid: "PAT-002",
    aestdat: "2024-01-20",
    aeterm: "Chest Pain",
    aeser: "Yes",
  },
  {
    id: 3,
    usubjid: "PAT-003",
    aestdat: "2024-02-01",
    aeterm: "Acute Respiratory Distress",
    aeser: "Yes",
  },
  {
    id: 4,
    usubjid: "PAT-004",
    aestdat: "2024-02-15",
    aeterm: "Severe Allergic Reaction",
    aeser: "Yes",
  },
  {
    id: 5,
    usubjid: "PAT-005",
    aestdat: "2024-03-01",
    aeterm: "Cardiac Arrhythmia",
    aeser: "Yes",
  },
  {
    id: 6,
    usubjid: "PAT-006",
    aestdat: "2024-03-10",
    aeterm: "Seizure",
    aeser: "Yes",
  },
  {
    id: 7,
    usubjid: "PAT-007",
    aestdat: "2024-03-15",
    aeterm: "Deep Vein Thrombosis",
    aeser: "Yes",
  },
  {
    id: 8,
    usubjid: "PAT-008",
    aestdat: "2024-03-20",
    aeterm: "Acute Kidney Injury",
    aeser: "Yes",
  },
  {
    id: 9,
    usubjid: "PAT-009",
    aestdat: "2024-03-25",
    aeterm: "Sepsis",
    aeser: "Yes",
  },
  {
    id: 10,
    usubjid: "PAT-010",
    aestdat: "2024-03-30",
    aeterm: "Pulmonary Embolism",
    aeser: "Yes",
  },
];

const SAENarrativesPage = () => {
  const [narratives, setNarratives] = useState([]);
  const [filteredNarratives, setFilteredNarratives] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  // Replace the useEffect that fetches data with this:
  useEffect(() => {
    const fetchNarratives = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("No authentication token found");
          navigate("/login"); // Redirect to login if no token
          return;
        }

        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };

        console.log("API_BASE_URL:", API_BASE_URL);
        console.log("Fetching from:", `${API_BASE_URL}/api/sae-narratives/`); // Debug URL
        const response = await axios.get(
          `${API_BASE_URL}/api/sae-narratives/`,
          config
        );

        const formattedData = response.data.map((narrative) => ({
          ae_index: narrative.ae_index,
          patient_id: narrative.participant_id,
          aestdat: narrative.ae_date_of_study,
          aeterm: narrative.preferred_term,
          aeser: narrative.result_of_seriousness,
        }));
        setNarratives(formattedData);
        setFilteredNarratives(formattedData);
      } catch (error) {
        console.error("Error fetching narratives:", error.response || error); // Log full error details
      }
    };

    fetchNarratives();
  }, []);

  // Handle search
  useEffect(() => {
    const filtered = narratives.filter((narrative) =>
      Object.values(narrative).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredNarratives(filtered);
    setPage(0);
  }, [searchTerm, narratives]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = (id) => {
    navigate(`/reports/sae-narratives/view/${id}`);
  };

  const handleEdit = (id) => {
    // Implement edit functionality
    console.log("Editing narrative:", id);
  };

  const handleDownload = (patient_id, ae_id) => {
    // Construct S3 URL with the id
    const s3Url = `https://octozidemodata.s3.us-east-1.amazonaws.com/SAE_Patient_Narratives/rpt-${patient_id}_AE_index-${ae_id}.docx`;

    // Create link element
    const link = document.createElement("a");
    link.href = s3Url;
    link.download = `SAE_Narrative_${ae_id}.docx`;

    // Append to body, trigger click, and cleanup
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        SAE Narratives
      </Typography>

      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <SearchIcon sx={{ color: "action.active", mr: 1 }} />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search narratives..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ maxWidth: 500 }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient ID</TableCell>
              <TableCell>AE Date</TableCell>
              <TableCell>Name of AE</TableCell>
              <TableCell align="center">Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredNarratives
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((narrative) => (
                <TableRow key={narrative.ae_index}>
                  <TableCell>{narrative.patient_id}</TableCell>
                  <TableCell>
                    {new Date(narrative.aestdat).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{narrative.aeterm.toUpperCase()}</TableCell>
                  <TableCell align="center">
                    {/* <Tooltip title="View">
                      <IconButton
                        onClick={() => handleView(narrative.ae_index)}
                        color="primary"
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => handleEdit(narrative.ae_index)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Download">
                      <IconButton
                        onClick={() =>
                          handleDownload(
                            narrative.patient_id,
                            narrative.ae_index
                          )
                        }
                        color="primary"
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredNarratives.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default SAENarrativesPage;
