// src/pages/HomePage.js
import {
  Card,
  Grid,
  CardContent,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useProject } from "../../../contexts/ProjectContext";
import { countUnreviewedEntries } from "../../DataCleaningStatus";
import axios from "axios";
import API_BASE_URL from "../../../config";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../misc/TooltipIcon";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const HomePage = () => {
  const { selectedProject, studies, isLoading, refetchProjectData } =
    useProject();
  const [aeEntries, setAEEntries] = useState([]);
  const [aeEntriesWithErrors, setAEEntriesWithErrors] = useState([]);
  const [aeEntriesWithDiscrepancies, setAEEntriesWithDiscrepancies] = useState(
    []
  );
  const [aeEntriesWithReviewStatus, setAEEntriesWithReviewStatus] = useState(
    []
  );
  // TODO: Get study details
  const selectedStudy = studies.find((study) => study.id === selectedProject);

  // TODO: Get highlevel study statistics

  //// Get the number of AE entries
  useEffect(() => {
    const fetchAEEntries = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/get_ae_entries/${selectedProject}/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntries(response.data);
        } catch (error) {
          console.error("Error fetching AE entries:", error);
        }
      }
    };

    fetchAEEntries();
  }, [selectedProject]);

  //// Get the number of AE Entries with errors
  useEffect(() => {
    const fetchAEEntriesWithErrors = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/ae-entries-with-errors/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntriesWithErrors(response.data);
        } catch (error) {
          console.error("Error fetching AE entries with errors:", error);
        }
      }
    };

    fetchAEEntriesWithErrors();
  }, [selectedProject]);

  //// Get the number of AE Entries with discrepancies
  useEffect(() => {
    const fetchAEEntriesWithDiscrepancies = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/ae-entries-with-discrepancies/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntriesWithDiscrepancies(response.data);
        } catch (error) {
          console.error("Error fetching AE entries with discrepancies:", error);
        }
      }
    };

    fetchAEEntriesWithDiscrepancies();
  }, [selectedProject]);

  //// Get the number of AE Entries with Queried status
  useEffect(() => {
    const fetchAEEntriesWithReviewStatus = async () => {
      const token = localStorage.getItem("authToken");
      if (token && selectedProject) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/get_ae_entries_with_review_status/Queried/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          setAEEntriesWithReviewStatus(response.data);
        } catch (error) {
          console.error("Error fetching AE entries with review status:", error);
        }
      }
    };

    fetchAEEntriesWithReviewStatus();
  }, [selectedProject]);

  //// Get the number of unreviewed entries and errors from the aeEntries prop
  console.log("aeEntries", aeEntries);
  console.log("aeEntriesWithErrors", aeEntriesWithErrors);
  const unreviewedCount = countUnreviewedEntries(aeEntries);
  const majorDiscrepancyCount = aeEntriesWithErrors.length;
  // const discrepancyCount = aeEntriesWithDiscrepancies.length;
  const discrepancyCount = aeEntriesWithDiscrepancies.length;

  // Handle row clicks
  const navigate = useNavigate();

  const handleRowClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (!studies.length || !selectedProject) {
      refetchProjectData();
    }
  }, [studies, selectedProject, refetchProjectData]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  // Add this color list at the top of your file or in a separate constants file
  const categoryColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#C9CBCF",
    "#4BC0C0",
    "#FF6384",
  ];

  // Mock data for reconciliation entries distribution
  const reconciliationEntries = {
    AE: aeEntries.length,
    Lab: 0,
    ConMed: 0,
    VitalSigns: 0,
    ECG: 0,
  };

  // Mock data for entry status distribution
  const entryStatus = {
    NoDiscrepancies: aeEntries.length - discrepancyCount,
    Major: majorDiscrepancyCount,
    NonMajor: discrepancyCount - majorDiscrepancyCount,
  };

  return (
    <div>
      {/* Study details card */}
      <Card
        sx={{
          height: "100%",
          flexGrow: 1,
          mb: 4,
          mt: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="title"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                {selectedStudy ? selectedStudy.title : "No study selected"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="h3" variant="title" gutterBottom>
                Protocol Number: {selectedStudy ? selectedStudy.name : "Name"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="h3" variant="title" gutterBottom>
                Sponsor: {selectedStudy ? selectedStudy.sponsor : "Sponsor"}
              </Typography>
            </Grid>
            {/* Additional Grid items can be added here as needed */}
            <Grid item xs={4}>
              <Typography component="h3" variant="title" gutterBottom>
                Phase: {selectedStudy ? selectedStudy.phase : "Phase"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="h3" variant="title" gutterBottom>
                Study Drug:{" "}
                {selectedStudy ? selectedStudy.study_drug : "Study Drug"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="h3" variant="title" gutterBottom>
                Clinical Lead:{" "}
                {selectedStudy ? selectedStudy.clinical_lead : "Clinical Lead"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="h3" variant="title" gutterBottom>
                Start Date:{" "}
                {selectedStudy ? selectedStudy.start_date : "Start Date"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Highlevel study statistics card */}
      <Grid container spacing={2} columns={12} sx={{ mb: 4 }}>
        {/* Reconciliation Entries Distribution */}
        <Grid item xs={6}>
          <Card sx={{ height: "100%", flexGrow: 1 }}>
            <CardContent>
              <Typography component="h2" variant="h6" gutterBottom>
                New Data Entries
                <InfoTooltip tooltipText="Distribution of new data entries across different categories." />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PieChart
                  series={[
                    {
                      data: Object.entries(reconciliationEntries).map(
                        ([category, count], index) => ({
                          id: index,
                          value: count,
                          label: category,
                          color: categoryColors[index % categoryColors.length],
                        })
                      ),
                      highlightScope: {
                        faded: "global",
                        highlighted: "item",
                      },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                      arcLabel: (item) => `${item.value}`,
                      arcLabelMinAngle: 45,
                    },
                  ]}
                  width={400}
                  height={250}
                  margin={{ top: 0, right: 200, bottom: 0, left: 10 }}
                  slotProps={{
                    legend: {
                      direction: "column",
                      position: { vertical: "middle", horizontal: "right" },
                      padding: 0,
                      labelStyle: {
                        fontSize: 12,
                      },
                    },
                  }}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: "white",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Entry Status Distribution */}
        <Grid item xs={6}>
          <Card sx={{ height: "100%", flexGrow: 1 }}>
            <CardContent>
              <Typography component="h2" variant="h6" gutterBottom>
                Entry Discrepancy Distribution
                <InfoTooltip tooltipText="Distribution of entry discrepancies: no, non-major, and non-major discrepancies." />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: entryStatus.NoDiscrepancies,
                          label: "No Discrepancies",
                          color: "#4caf50",
                        },
                        {
                          id: 1,
                          value: entryStatus.NonMajor,
                          label: "Non-Major",
                          color: "#ff9800",
                        },
                        {
                          id: 2,
                          value: entryStatus.Major,
                          label: "Major",
                          color: "#f44336",
                        },
                      ],
                      highlightScope: {
                        faded: "global",
                        highlighted: "item",
                      },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                      arcLabel: (item) => `${item.value}`,
                      arcLabelMinAngle: 45,
                    },
                  ]}
                  width={400}
                  height={250}
                  margin={{ top: 0, right: 200, bottom: 0, left: 10 }}
                  slotProps={{
                    legend: {
                      direction: "column",
                      position: { vertical: "middle", horizontal: "right" },
                      padding: 0,
                      labelStyle: {
                        fontSize: 12,
                      },
                    },
                  }}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: "white",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Table of reconciliation statuses */}
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Entry Category</TableCell>
              <TableCell>Discrepancies</TableCell>
              <TableCell>Major Discrepancies</TableCell>
              <TableCell>Unreviewed Entries</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              hover
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick("/ae-reconciliation/")}
            >
              <TableCell>Adverse Event</TableCell>
              <TableCell>{discrepancyCount}</TableCell>
              <TableCell>{majorDiscrepancyCount}</TableCell>
              <TableCell>{unreviewedCount}</TableCell>
            </TableRow>
            <TableRow
              hover
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick("/conmed-reconciliation/")}
            >
              <TableCell>Concomitant Medication</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow
              hover
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick("/lab-reconciliation/")}
            >
              <TableCell>Lab</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow
              hover
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick("/vital-sign-reconciliation/")}
            >
              <TableCell>Vital Sign</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow
              hover
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick("/ie-criteria-reconciliation/")}
            >
              <TableCell>I/E Criteria</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow
              hover
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick("/ecg-reconciliation/")}
            >
              <TableCell>ECG</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HomePage;
