import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { CheckCircle, Warning, Error } from "@mui/icons-material";

const StatusIcon = ({ status }) => {
  switch (status) {
    case "Success":
      return <CheckCircle style={{ color: "green" }} />;
    case "Warning":
      return <Warning style={{ color: "orange" }} />;
    case "Error":
      return <Error style={{ color: "red" }} />;
    default:
      return null;
  }
};

const EditChecksTable = ({ aeEditChecks }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aeEditChecks.map((check, index) => (
            <Tooltip key={index} title={check.description} placement="top">
              <TableRow>
                <TableCell>{check.title}</TableCell>
                <TableCell>
                  <StatusIcon status={check.status} />
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditChecksTable;
