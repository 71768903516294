import React from "react";
import { Box, Typography } from "@mui/material";

const ECGReconciliationPage = () => {
  return (
    <>
      <h1>ECG Reconciliation Page</h1>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="60vh"
      >
        <Typography variant="h4" gutterBottom>
          This feature is on its way.
        </Typography>
      </Box>
    </>
  );
};

export default ECGReconciliationPage;
