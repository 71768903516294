import { Fragment } from "react";
import { Link } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import PeopleIcon from "@mui/icons-material/People";
// import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

export const mainListItems = (
  <Fragment>
    <ListItemButton button component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    {/* <ListItemButton button component={Link} to="/">
      <ListItemIcon>
        <TrendingUpIcon />
      </ListItemIcon>
      <ListItemText primary="Trends" />
    </ListItemButton> */}
    <ListItemButton button component={Link} to="/visualizations/">
      <ListItemIcon>
        <LeaderboardIcon />
      </ListItemIcon>
      <ListItemText primary="Visualizations" />
    </ListItemButton>
    <ListItemButton button component={Link} to="/study-reports/">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton button component={Link} to="/integrations/">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton>
    <ListItemButton button component={Link} to="/study-documents/">
      <ListItemIcon>
        <SummarizeIcon />
      </ListItemIcon>
      <ListItemText primary="Study Documents" />
    </ListItemButton>
    {/* <ListItemButton button component={Link} to="/">
      <ListItemIcon>
        <QuestionAnswerIcon />
      </ListItemIcon>
      <ListItemText primary="Query Log" />
    </ListItemButton> */}
    <ListItemButton button component={Link} to="/audit-log/">
      <ListItemIcon>
        <TroubleshootIcon />
      </ListItemIcon>
      <ListItemText primary="Audit Log" />
    </ListItemButton>
  </Fragment>
);

export const secondaryListItems = (
  <Fragment>
    {/* <ListItemButton button component={Link} to="/">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton> */}
    {/* <ListItemButton button component={Link} to="/">
      <ListItemIcon>
        <HelpIcon />
      </ListItemIcon>
      <ListItemText primary="Help" />
    </ListItemButton> */}
    <ListItemButton button component={Link} to="/account">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Account" />
    </ListItemButton>
  </Fragment>
);
