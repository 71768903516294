import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Button,
  TableHead,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import axios from "axios";
import API_BASE_URL from "../../../config";

function TreatmentEmergentTableStaticPage() {
  const [aeData, setAEData] = useState({});

  useEffect(() => {
    console.log("useEffect triggered");

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://octozidemodata.s3.us-east-1.amazonaws.com/Reports/ATEAE/preferred_ae_results.json",
          {
            // Add headers to handle CORS and specify content type
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data) {
          setAEData(response.data);
          console.log("aeData state updated:", response.data);
        } else {
          console.error("No data received from API");
        }
      } catch (error) {
        console.error("Error fetching AE data:", error);
        // Log more detailed error information
        if (error.response) {
          console.error("Error response:", error.response);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        }
      }
    };

    // Immediately invoke fetchData
    fetchData();

    // Add a cleanup function
    return () => {
      console.log("useEffect cleanup");
    };
  }, []);

  console.log("Current aeData state:", aeData);

  const generateCSV = () => {
    // Create headers
    const headers = [
      "Preferred Term",
      "Occurrences",
      "Mild - Grade 1",
      "Moderate - Grade 2",
      "Severe - Grade 3",
      "Life-threatening - Grade 4",
      "Death - Grade 5",
      "Related to Study Drug",
      "Related to Other Treatment",
      "SAE",
      "Ongoing",
    ];

    // Create rows from data
    const rows = Object.entries(aeData).map(([term, data]) => [
      term,
      data.num_rows,
      data.severity_grades_distribution["Mild/Grade 1"] || 0,
      data.severity_grades_distribution["Moderate/Grade 2"] || 0,
      data.severity_grades_distribution["Severe/Grade 3"] || 0,
      data.severity_grades_distribution["Life-threatening/Grade 4"] || 0,
      data.severity_grades_distribution["Death/Grade 5"] || 0,
      data.relation_to_study_drug_distribution["Suspected"] || 0,
      data.relation_to_other_components_distribution["Suspected"] || 0,
      data.sae_status_distribution["Yes"] || 0,
      data.ongoing_status_distribution["Ongoing"] || 0,
    ]);

    // Combine headers and rows
    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "treatment_emergent_ae_table.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding="0px"
    >
      <Paper elevation={3} style={{ padding: "20px 20px 10px", width: "95%" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          mt={2}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h4" component="h1">
              All Treatment Emergent Adverse Events
            </Typography>
            <Tooltip
              title={
                <Box sx={{ p: 1 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>Table Information:</strong>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    • Highlighted rows (orange) indicate adverse events not in
                    preclinical evidence
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    • "Related to Study Drug" shows the number of AEs suspected
                    to be related to the study medication
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    • "Related to Other Treatment" indicates AEs suspected to be
                    related to concurrent medications or procedures
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    • "SAE" shows the count of serious adverse events
                  </Typography>
                  <Typography variant="body2">
                    • "Ongoing" displays the number of AEs that were not
                    resolved at the time of reporting
                  </Typography>
                </Box>
              }
              arrow
              placement="right"
              sx={{
                maxWidth: 500,
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "#f5f5f5",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "0.875rem",
                  border: "1px solid #dadde9",
                },
              }}
            >
              <IconButton color="primary" size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={generateCSV}
            sx={{
              height: "fit-content",
              minWidth: "150px",
              fontWeight: "bold",
            }}
          >
            Generate CSV
          </Button>
        </Box>

        <TableContainer sx={{ maxHeight: "calc(100vh - 200px)" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#fff",
                    left: 0,
                    position: "sticky",
                    zIndex: 1000,
                  }}
                >
                  Preferred Term
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Occurrences
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Mild - Grade 1
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Moderate - Grade 2
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Severe - Grade 3
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Life-threatening - Grade 4
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Death - Grade 5
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Related to Study Drug
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Related to Other Treatment
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  SAE
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#fff" }}>
                  Ongoing
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(aeData).map(([term, data]) => (
                <TableRow
                  key={term}
                  sx={{
                    backgroundColor: data.preclinical_ae
                      ? "#fff3e0"
                      : "inherit", // Light orange background for preclinical AEs
                    "&:hover": {
                      backgroundColor: data.preclinical_ae
                        ? "#ffe0b2"
                        : "#f5f5f5", // Darker shade on hover
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      left: 0,
                      position: "sticky",
                      backgroundColor: data.preclinical_ae ? "#fff3e0" : "#fff", // Match the row background
                      zIndex: 900,
                      "&:hover": {
                        backgroundColor: data.preclinical_ae
                          ? "#ffe0b2"
                          : "#f5f5f5",
                      },
                    }}
                  >
                    {term}
                  </TableCell>
                  <TableCell align="center">{data.num_rows}</TableCell>
                  <TableCell align="center">
                    {data.severity_grades_distribution["Mild/Grade 1"] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.severity_grades_distribution["Moderate/Grade 2"] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.severity_grades_distribution["Severe/Grade 3"] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.severity_grades_distribution[
                      "Life-threatening/Grade 4"
                    ] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.severity_grades_distribution["Death/Grade 5"] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.relation_to_study_drug_distribution["Suspected"] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.relation_to_other_components_distribution[
                      "Suspected"
                    ] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.sae_status_distribution["Yes"] || 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.ongoing_status_distribution["Ongoing"] || 0}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default TreatmentEmergentTableStaticPage;
