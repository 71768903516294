import React, { useState, useEffect } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  alignSelf: "flex-start",
  marginBottom: theme.spacing(2),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "calc(100vh - 250px)",
  overflowY: "auto",
}));

const AuditLogPage = () => {
  const [logEntries, setLogEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAuditLog();
  }, []);

  const fetchAuditLog = async () => {
    try {
      const response = await axios.get(
        "https://octozidemodata.s3.amazonaws.com/logs/front_facing_user_actions_log.jsonl"
      );
      const logContent = response.data;
      const entries = logContent.split("\n").filter(Boolean).map(JSON.parse);
      setLogEntries(entries.reverse());
      setLoading(false);
    } catch (err) {
      console.error("Error fetching audit log:", err);
      setError(`Failed to load audit log: ${err.message}`);
      setLoading(false);
    }
  };

  const downloadAuditLog = () => {
    const content = logEntries.slice().reverse().map(JSON.stringify).join("\n");
    const blob = new Blob([content], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "audit_log.jsonl";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = parseISO(timestamp);
      return format(date, "MMM d, yyyy HH:mm:ss");
    } catch (error) {
      console.error("Error parsing timestamp:", error);
      return timestamp; // Return original timestamp if parsing fails
    }
  };

  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          Audit Log
        </Typography>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <StyledButton
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={downloadAuditLog}
            >
              Download Audit Log
            </StyledButton>
            <StyledTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logEntries.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{formatTimestamp(entry.timestamp)}</TableCell>
                      <TableCell>{entry.user}</TableCell>
                      <TableCell>{entry.details}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </>
        )}
      </StyledPaper>
    </Container>
  );
};

export default AuditLogPage;
