import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ReportNavigationPage = () => {
  const navigate = useNavigate();

  const reportCards = [
    {
      title: "All Treatment Emergent Adverse Events",
      description:
        "View and analyze all treatment emergent adverse events data",
      path: "/reports/treatment-adverse-event-table",
    },
    {
      title: "SAE Narratives",
      description: "View, edit, and download SAE narrative listings",
      path: "/reports/sae-narratives",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Report Modules
      </Typography>
      <Grid container spacing={3}>
        {reportCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 3,
                },
              }}
            >
              <CardActionArea
                onClick={() => navigate(card.path)}
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ReportNavigationPage;
