export const countUnreviewedEntries = (reports) => {
  if (!reports || reports.length === 0) {
    return 0;
  }

  return reports.filter((report) => report.review_status === "Unreviewed")
    .length;
};

export const countDiscrepancyEntries = (reports) => {
  if (!reports || reports.length === 0) {
    return 0;
  }

  return reports.filter((report) => report.discrepency === true).length;
};
