import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../config";
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import Plotly from "plotly.js-dist";
import "./patientProfileDetailsPage.css";
import JSZip from "jszip";
import { Button, ButtonGroup } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";

const PLOT_TYPES = [
  { id: "adverse_events", title: "Adverse Events" },
  { id: "ancillary_procedures", title: "Ancillary Procedures" },
  { id: "concomitant_medications", title: "Concomitant Medications" },
  { id: "study_drug", title: "Study Drug" },
  { id: "vital_signs", title: "Vital Signs" },
  { id: "labs_chemistry", title: "Labs (Chemistry)" },
  { id: "labs_hematology", title: "Labs (Hematology)" },
];

const PatientProfileDetailsPage = () => {
  const { patientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [availablePlots, setAvailablePlots] = useState({});
  const [expandedPlots, setExpandedPlots] = useState(
    PLOT_TYPES.reduce((acc, { id }) => ({ ...acc, [id]: true }), {})
  );
  const [patientNarrative, setPatientNarrative] = useState("");
  const [patientNarrativeExists, setPatientNarrativeExists] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [plotMenuAnchor, setPlotMenuAnchor] = useState(null);
  const [selectedPlotType, setSelectedPlotType] = useState(null);
  const [globalMenuAnchor, setGlobalMenuAnchor] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");

        // Fetch patient profiles to get demographic info
        const profilesResponse = await axios.get(
          `${API_BASE_URL}/api/patient-profiles/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        // Find the specific patient's info
        const patientData = profilesResponse.data.find(
          (profile) => profile.patient_id === patientId
        );
        setPatientInfo(patientData);

        // Rest of your existing fetch operations...
        const profileResponse = await axios.get(
          `${API_BASE_URL}/api/patient-profiles/${patientId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setProfileData(profileResponse.data);

        // Fetch patient narrative...
        try {
          const response = await fetch(
            `/patient_profiles/patient_narrative_${patientId}.txt`
          );
          if (response.ok) {
            const text = await response.text();
            setPatientNarrative(text);
            setPatientNarrativeExists(true);
          } else {
            setPatientNarrative(
              "Patient narrative has not been generated for this patient."
            );
            setPatientNarrativeExists(false);
          }
        } catch (error) {
          console.error("Error fetching patient narrative:", error);
          setPatientNarrative("Error loading patient narrative.");
          setPatientNarrativeExists(false);
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
        setError("Failed to fetch patient profile data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [patientId]);

  useEffect(() => {
    setAvailablePlots({});

    const loadPlots = async () => {
      if (!profileData?.files) return;

      for (const { id: plotType } of PLOT_TYPES) {
        const plotFile = profileData.files.find(
          (file) => file.name === `${plotType}.json`
        );

        if (!plotFile) {
          setAvailablePlots((prev) => ({ ...prev, [plotType]: false }));
          continue;
        }

        try {
          const response = await axios.get(plotFile.url);
          const plotJson = response.data;
          setAvailablePlots((prev) => ({ ...prev, [plotType]: true }));

          setTimeout(() => {
            const plotDiv = document.getElementById(`plot-${plotType}`);
            if (plotDiv) {
              try {
                Plotly.newPlot(
                  `plot-${plotType}`,
                  plotJson.data,
                  {
                    ...plotJson.layout,
                    autosize: true,
                    margin: { t: 50, b: 50, l: 50, r: 50 },
                  },
                  {
                    responsive: true,
                    displayModeBar: false,
                  }
                );
              } catch (error) {
                console.error(`Error plotting ${plotType}:`, error);
                setAvailablePlots((prev) => ({
                  ...prev,
                  [plotType]: false,
                }));
              }
            }
          }, 0);
        } catch (error) {
          console.error(`Error fetching ${plotType}:`, error);
          setAvailablePlots((prev) => ({ ...prev, [plotType]: false }));
        }
      }
    };

    loadPlots();

    return () => {
      PLOT_TYPES.forEach(({ id: plotType }) => {
        const plotDiv = document.getElementById(`plot-${plotType}`);
        if (plotDiv) {
          Plotly.purge(plotDiv);
        }
      });
    };
  }, [profileData]);

  const togglePlot = (plotType) => {
    setExpandedPlots((prev) => ({
      ...prev,
      [plotType]: !prev[plotType],
    }));
  };

  const downloadAllPlots = async (extension) => {
    if (!profileData?.files) {
      console.error("No files available in profileData");
      return;
    }

    // Filter files by extension
    const filesToDownload = profileData.files.filter((file) =>
      file.name.endsWith(`.${extension}`)
    );

    if (filesToDownload.length === 0) {
      console.error(`No files found with extension .${extension}`);
      return;
    }

    const zip = new JSZip();

    try {
      // Download each file and add to zip
      await Promise.all(
        filesToDownload.map(async (file) => {
          try {
            const response = await axios.get(file.url);
            const content =
              extension === "json"
                ? JSON.stringify(response.data, null, 2) // Pretty print JSON
                : response.data;

            zip.file(file.name, content);
          } catch (error) {
            console.error(`Error downloading ${file.name}:`, error);
          }
        })
      );

      // Generate and download zip file
      const content = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = `patient_${patientId}_plots_${extension}.zip`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating zip file:", error);
    }
  };

  const downloadPlotFile = async (plotType, extension) => {
    if (!profileData?.files) {
      console.error("No files available in profileData");
      return;
    }

    const file = profileData.files.find(
      (file) => file.name === `${plotType}.${extension}`
    );

    if (!file) {
      console.error(`No ${extension} file found for ${plotType}`);
      return;
    }

    try {
      const response = await axios.get(file.url);
      const content =
        extension === "json"
          ? JSON.stringify(response.data, null, 2)
          : response.data;

      const blob = new Blob([content], {
        type: extension === "json" ? "application/json" : `text/${extension}`,
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading ${file.name}:`, error);
    }
  };

  const handlePlotMenuOpen = (event, plotType) => {
    event.stopPropagation();
    setPlotMenuAnchor(event.currentTarget);
    setSelectedPlotType(plotType);
  };

  const handlePlotMenuClose = (event) => {
    event?.stopPropagation();
    setPlotMenuAnchor(null);
    setSelectedPlotType(null);
  };

  const handleGlobalMenuOpen = (event) => {
    setGlobalMenuAnchor(event.currentTarget);
  };

  const handleGlobalMenuClose = () => {
    setGlobalMenuAnchor(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div className="page-container">
      <Paper elevation={3} sx={{ p: 2, mb: 3, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Patient Profile (ID): {patientId}
          </Typography>
          <IconButton onClick={handleGlobalMenuOpen}>
            <DownloadIcon />
          </IconButton>
          <Menu
            anchorEl={globalMenuAnchor}
            open={Boolean(globalMenuAnchor)}
            onClose={handleGlobalMenuClose}
          >
            {/* <MenuItem
              onClick={() => {
                downloadAllPlots("png");
                handleGlobalMenuClose();
              }}
            >
              Download All as PNG
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                downloadAllPlots("html");
                handleGlobalMenuClose();
              }}
            >
              Download All as HTML
            </MenuItem>
            <MenuItem
              onClick={() => {
                downloadAllPlots("json");
                handleGlobalMenuClose();
              }}
            >
              Download All as JSON
            </MenuItem>
          </Menu>
        </Box>
        {patientInfo && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" gutterBottom>
              {patientInfo.site_id && (
                <>
                  Site: <strong>{patientInfo.site_id}</strong> |{" "}
                </>
              )}
              {patientInfo.age && (
                <>
                  Age: <strong>{patientInfo.age}</strong> |{" "}
                </>
              )}
              {patientInfo.sex && (
                <>
                  Sex: <strong>{patientInfo.sex}</strong> |{" "}
                </>
              )}
              {patientInfo.race && (
                <>
                  Race: <strong>{patientInfo.race}</strong> |{" "}
                </>
              )}
              {patientInfo.treatment_arm && (
                <>
                  Treatment Arm: <strong>{patientInfo.treatment_arm}</strong>
                </>
              )}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {patientInfo.study_status && (
                <>
                  Study Status: <strong>{patientInfo.study_status}</strong> |{" "}
                </>
              )}
              {patientInfo.enrollment_date && (
                <>
                  Enrollment Date:{" "}
                  <strong>{patientInfo.enrollment_date}</strong> |{" "}
                </>
              )}
              {patientInfo.last_visit_date && (
                <>
                  Last Visit Date:{" "}
                  <strong>{patientInfo.last_visit_date}</strong>
                </>
              )}
            </Typography>
            {patientInfo.discontinuation_reason && (
              <Typography variant="body1" color="error.main">
                <strong>Discontinuation Reason:</strong>{" "}
                {patientInfo.discontinuation_reason}
              </Typography>
            )}
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 3, width: "100%" }}>
        <Typography variant="h5" gutterBottom>
          Generated Patient Narrative
        </Typography>
        {patientNarrativeExists ? (
          <pre
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              maxHeight: "100%",
              overflowY: "auto",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f8f8f8",
              margin: 0,
            }}
          >
            {patientNarrative}
          </pre>
        ) : (
          <Typography color="text.secondary">{patientNarrative}</Typography>
        )}
      </Paper>

      <div className="plots-container">
        {PLOT_TYPES.map(({ id: plotType, title }) => (
          <div key={plotType} className="plot-section">
            <div
              className={`plot-header ${
                availablePlots[plotType] ? "clickable" : ""
              }`}
              onClick={() => availablePlots[plotType] && togglePlot(plotType)}
            >
              <h3 className="plot-title">
                {title}{" "}
                {availablePlots[plotType] && (
                  <>
                    <IconButton
                      size="small"
                      onClick={(e) => handlePlotMenuOpen(e, plotType)}
                      sx={{ ml: "auto" }}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <Menu
                      anchorEl={plotMenuAnchor}
                      open={
                        Boolean(plotMenuAnchor) && selectedPlotType === plotType
                      }
                      onClose={handlePlotMenuClose}
                    >
                      {/* <MenuItem
                        onClick={(e) => {
                          downloadPlotFile(plotType, "png");
                          handlePlotMenuClose(e);
                        }}
                      >
                        Download as PNG
                      </MenuItem> */}
                      <MenuItem
                        onClick={(e) => {
                          downloadPlotFile(plotType, "html");
                          handlePlotMenuClose(e);
                        }}
                      >
                        Download as HTML
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          downloadPlotFile(plotType, "json");
                          handlePlotMenuClose(e);
                        }}
                      >
                        Download as JSON
                      </MenuItem>
                    </Menu>
                  </>
                )}{" "}
                {availablePlots[plotType] && (
                  <span className="expand-icon">
                    {expandedPlots[plotType] ? "▼" : "▶"}
                  </span>
                )}
              </h3>
            </div>
            {availablePlots[plotType] ? (
              <div
                className={`plot-wrapper ${
                  expandedPlots[plotType] ? "expanded" : ""
                }`}
              >
                <div id={`plot-${plotType}`} className="plot" />
              </div>
            ) : (
              <p className="no-data-message">No data to produce graph</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatientProfileDetailsPage;
