import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import API_BASE_URL from "../../../config";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

// Add this function to get the CSRF token from cookies
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export default function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  // Extract uidb64 and token from the URL path
  const pathParts = location.pathname.split("/");
  const uidb64 = pathParts[pathParts.length - 3];
  const token = pathParts[pathParts.length - 2];

  React.useEffect(() => {
    // Add debug logging
    console.log("Current pathname:", location.pathname);
    console.log("Extracted uidb64:", uidb64);
    console.log("Extracted token:", token);
  }, [location.pathname, uidb64, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    try {
      // Get CSRF token
      const csrfToken = getCookie("csrftoken");

      const response = await axios.post(
        `${API_BASE_URL}/api/password-reset-confirm/${uidb64}/${token}/`,
        {
          new_password1: password,
          new_password2: confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken, // Add CSRF token
          },
          withCredentials: true, // Important for CORS with credentials
        }
      );

      console.log("Password reset successful:", response.data);
      setSuccess(true);

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error(
        "Password reset error:",
        error.response?.data || error.message
      );
      setError(
        error.response?.data?.error ||
          error.response?.data?.detail ||
          "An error occurred. Please try again."
      );
    }
  };

  return (
    <Card variant="outlined">
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <img
          src="/Octozi_Logo_Light.png"
          alt="Octozi Logo"
          style={{ width: "100px", height: "auto" }}
        />
      </Box>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
      >
        Reset Password
      </Typography>
      {success ? (
        <Alert severity="success">
          Password successfully reset! Redirecting to login page...
        </Alert>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="password">New Password</FormLabel>
            <TextField
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••"
              type="password"
              id="password"
              required
              fullWidth
              variant="outlined"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
            <TextField
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="••••••"
              type="password"
              id="confirmPassword"
              required
              fullWidth
              variant="outlined"
            />
          </FormControl>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          <Button type="submit" fullWidth variant="contained">
            Reset Password
          </Button>
        </Box>
      )}
    </Card>
  );
}
