// src/components/TopNavBar.js
import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import { useProject } from "../contexts/ProjectContext";
import { useNavigate } from "react-router-dom";

const TopNavBar = ({ open, toggleDrawer }) => {
  const { selectedProject, setSelectedProject, studies } = useProject();
  const navigate = useNavigate();

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar sx={{ pr: "24px" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <IconButton
          color="inherit"
          aria-label="go back"
          onClick={handleBackClick}
          sx={{
            marginRight: 2,
            marginLeft: open ? "240px" : 0, // Add this line
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton> */}

          <Typography variant="h6" sx={{ color: "white", marginRight: 2 }}>
            Study:
          </Typography>

          <Select
            value={selectedProject}
            onChange={handleProjectChange}
            sx={{ color: "white", borderColor: "white" }}
          >
            {studies.map((study) => (
              <MenuItem key={study.id} value={study.id}>
                <Typography variant="h6">{study.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
