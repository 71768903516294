import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../config";

const EmailConfirmation = () => {
  const [status, setStatus] = useState("Confirming...");
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        console.log("Confirming email with token:", token);
        const response = await axios.get(
          `${API_BASE_URL}/api/confirm-email/${token}/`
        );
        console.log("Response:", response.data);
        setStatus("Email confirmed successfully. Redirecting to login...");
        navigate("/login"); // Redirect immediately
      } catch (error) {
        console.error("Error during confirmation:", error);
        setStatus(
          error.response?.data?.detail ||
            "An error occurred during email confirmation."
        );
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return (
    <div>
      <h2>Email Confirmation</h2>
      <p>{status}</p>
    </div>
  );
};

export default EmailConfirmation;
