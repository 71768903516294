import React, { useState } from "react";
import { Box, Paper, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const NarrativeViewer = () => {
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const fileUrl = `${process.env.PUBLIC_URL}/rpt-1_AE_index-0.pdf`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{ p: 3, height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Paper
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">
          SAE Narrative Viewer ({numPages} {numPages === 1 ? "page" : "pages"})
        </Typography>
        <IconButton onClick={() => navigate(-1)} color="primary">
          <CloseIcon />
        </IconButton>
      </Paper>
      <Paper
        sx={{
          flex: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          gap: 2,
        }}
      >
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Typography>Loading document...</Typography>}
          error={<Typography color="error">Error loading document.</Typography>}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Box key={`page_${index + 1}`} sx={{ mb: 2 }}>
              <Page
                pageNumber={index + 1}
                renderTextLayer={true}
                renderAnnotationLayer={true}
                width={Math.min(window.innerWidth - 100, 800)}
              />
            </Box>
          ))}
        </Document>
      </Paper>
    </Box>
  );
};

export default NarrativeViewer;
